html{
  scroll-behavior: smooth;
  background : #E3E3E3;
  cursor: none !important;
}
body {
  margin: 0;
  font-family: 'M-R';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

ul{
  display: flex;
}


@font-face {
  font-family: 'M-R';
  src: local('PPMori-Regular.woff2'), url(./_fonts/PPMori-Regular.woff2) format('woff2');
}
@font-face {
  font-family: 'M-SB';
  src: local('PPMori-SemiBold.woff2'), url(./_fonts/PPMori-SemiBold.woff2) format('woff2');
}
