@import '../../Services/Styles.scss';

.contact{
    display          : flex;
    position         : relative;
    flex-direction   : row;
    background-color : $black;
    color            : $white;
    border-radius    : $border-radius-desktop;
    gap              : $padding-desktop;
    width            : 100%;
    max-width        : 2000px;

    &-marquee{
        position  : absolute;
        width     : 100%;
    }

    &-data{
        display         : flex;
        flex-direction  : row;
        justify-content : space-between;
        align-items     : flex-end;
        gap             : 16px;
        padding         : $padding-desktop;
        padding-top     : 120px;
        // width           : 100%;
        flex: 1;

        &-credits{
            color     : $grey02;
            font-size : $body-mini;
        }
        &-links{
            display        : flex;
            flex-direction : column;
            align-items    : end;
            gap            : 10px;
        }
    }
}

@media screen and (max-width: 950px) {
    .contact{
        flex-direction : column;
        gap            : 0px;
        border-radius  : $border-radius-mobile;
        
        &-data{
            flex-direction  : column;
            padding         : $padding-mobile;
            padding-top     : 75px;

            &-credits{
                order      : 2;
                width      : 100%;
                text-align : left;
                color      : $grey02;
            }
            &-links{
                width          : 100%;
                order          : 1;
                display        : flex;
                flex-direction : column;
                align-items    : end;
            }

        }
    }
}