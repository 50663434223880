@import './Services/Styles.scss';

h1{
    margin         : 0;
    font-family    : "M-SB";
    font-size      : 80px;
    letter-spacing : -4px;
    font-weight    : 700;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
h2{
    margin         : 0;
    line-height    : 60px;
    font-family    : "M-SB";
    font-size      : 60px;
    letter-spacing : -3px;
    font-weight    : 700;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
h3{
    margin         : 0;
    font-family    : "M-SB";
    line-height    : 30px;
    font-size      : 30px;
    letter-spacing : -1px;
    color          : $grey03;
    font-weight    : 700;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
a{
    text-decoration : none;
    cursor          : none;
}


.app{
    position    : relative;
    width       : 100%;
    height      : 100%;
    position    : relative;
    font-family : 'M-R';
}


	//------------------------------------------------------------//
	//---------------------- RESPONSIVE -------------------------//
   //------------------------------------------------------------//

@media screen and (max-width: 950px) {
    h1{
        font-size : 50px;
        font-weight    : 500;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    h2{
        line-height    : 35px;
        font-size      : 35px;
        letter-spacing : -2px;
        font-weight    : 500;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    h3{
        line-height    : 25px;
        font-size      : 25px;
        letter-spacing : -1px;
        font-weight    : 500;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}