@import '../../Services/Styles.scss';

.carousel-container{
   position : relative;
   width    : 100%;
   z-index  : 95;
   svg {
      path {
          stroke: $black;
      }  
  }
}

.carousel-button{
   position   : absolute;
   z-index    : 98;
   background : $white;
   padding    : 10px;
   

   &.--left{
      border-top-right-radius    : 6px;
      border-bottom-right-radius : 6px;
      left : 0;
      
   }
   &.--right{
      border-top-left-radius    : 6px;
      border-bottom-left-radius : 6px;
      right                     : 0;
   }

}