// PADDINGS
$padding-desktop : 20px;
$padding-mobile  : 14px;

// BORDER RADIUS
$border-radius-desktop : 20px;
$border-radius-mobile  : 14px;

// FONT SIZE
$body-mini  : 13px;
$body       : 15px;
$H1-desktop : 80px;
$H1-mobile  : 50px;
$H2-desktop : 50px;
$H2-mobile  : 35px;

// COLORS
$black  : #000000;
$white  : #FFFFFF;
$grey01 : #E3E3E3;
$grey02 : #A3A3A3;
$grey03 : #5C5C5C;


.disableSelection{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0;
}



