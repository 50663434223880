@import '../../Services/Styles.scss';

.marquee-about{
    position       : absolute;
    width          : 100%;
    mix-blend-mode : difference;
}

.about{
    display          : flex;
    position         : relative;
    flex-direction   : row;
    color            : $white;
    width            : 100%;
    max-width        : 2000px;

    &-img{
        width          : 40%;
        max-width      : 590px;
        object-fit     : cover;
        border-radius  : $border-radius-desktop;
    }

    &-data{
        display          : flex;
        flex-direction   : column;
        gap              : 16px;
        padding          : 120px $padding-desktop $padding-desktop $padding-desktop;
        background-color : $black;
        border-radius    : $border-radius-desktop;

        &-intro{
            color : $white;
        }

        &-tools{
            display          : flex;
            flex-direction   : column;
            gap              : 16px;
            padding          : $padding-desktop;
            border-radius    : 10px;
            background       : #202020;
            
            &-item{
                display        : flex;
                flex-direction : column;
                gap            : 6px;
    
                &-list{
                    display   : flex;
                    flex-wrap : wrap;
                    gap       : 2px 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 950px) {
    .about{
        flex-direction : column;
        gap            : 0px;
        border-radius  : $border-radius-mobile;

        &-img{
            width                   : 100%;
            height                  : 500px;
            max-width               : none;
            border-radius           : 0px;
            border-radius  : $border-radius-mobile;
        }
        &-data{
            padding : $padding-mobile;
            border-radius  : $border-radius-mobile;
            gap : 14px;
            
            &-tools{
                padding : $padding-mobile;

            }
        }
    }
}