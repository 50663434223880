@import '../../Services/Styles.scss';

.marquee{
    position                : absolute;
    overflow                : hidden;
    border-top-left-radius  : $border-radius-desktop;
    border-top-right-radius : $border-radius-desktop;

    &-white{
        color : $grey01;
    }
    &-grey{
        color : $grey01;
    }
}


.arrow{
    margin      : 0 20px;
    font-family : "M-R";
}