@import '../../Services/Styles.scss';

.blackscreen{
    position   : absolute;
    width      : 100%;
    height     : 100%;
    background : $black;
    z-index    : 998;
    animation  : fadeOutBG 2.5s forwards;
    pointer-events  : none;
    
}
.home {
    display          : flex;
    flex-direction   : column;
    color            : $black;
    background-color : $grey01;
    padding          : 10px;
    gap              : 8px;
    align-items      : center;
}

	//------------------------------------------------------------//
	//---------------------- ANIMATIONS -------------------------//
   //------------------------------------------------------------//

@keyframes fadeOutBG {
    0% {opacity: 100%;}
    100% {opacity: 0%;}
}
	//------------------------------------------------------------//
	//---------------------- RESPONSIVE -------------------------//
   //------------------------------------------------------------//
@media screen and (max-width: 870px) {
    .home{
        padding : 6px;
        gap     : 6px;
    }
}