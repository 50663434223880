@import '../../Services/Styles.scss';

.loaderPage{
    position         : relative;
    display          : flex;
    align-items      : center;
    justify-content  : center;
    font-size        : 25px;
    height           : 100vh;
    background-color : $black;
    flex-direction   : column;
    animation        : fadeInLoader 1s forwards;
}
@keyframes fadeInLoader {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
