@import '../../Services/Styles.scss';

.btn-underline{
    color : $white;

    &:hover{
        text-decoration       : underline 1px;
        text-underline-offset : 2px;
    }
}
    