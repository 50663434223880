@import '../../Services/Styles.scss';

.project-item{
   color         : $white;
   height        : 150px;
   border-radius : 6px;
   padding       : 0 3px;
   
   &-content{
      position      : relative;
      height        : 100%;
      width         : 100%;


      &-hover{
         border-radius : 6px;
         position      : absolute;
         height        : 100%;
         width         : 100%;
         z-index       : 4;

         &-text{
            display        : flex;
            position       : absolute;
            align-items    : flex-end;
            z-index        : 4;
            font-size      : $body;
            width          : 92%;
            height         : 100%;
            bottom         : 10px;
            left           : 10px;
            opacity        : 0;
            text-transform : uppercase;
            
         }
         &-cover{
            border-radius : 6px;
            z-index       : 3;
            position      : absolute;
            background    : $black;
            width         : 100%;
            height        : 100%;
            opacity       : 0;
         }
         &:hover{
            .project-item-content-hover-text{
               animation : fadeinHoverText 0.4s forwards;
            }
            .project-item-content-hover-icon{
               animation : fadeinHoverText 0.4s forwards;
            }
            .project-item-content-hover-cover{
               animation : fadeinHoverImg 0.4s forwards;
            }
         }
         &:not(:hover){
            .project-item-content-hover-text{
               animation : fadeOutHoverText 0.4s forwards;
            }
            .project-item-content-hover-icon{
               animation : fadeOutHoverText 0.4s forwards;
            }
            .project-item-content-hover-cover{
               animation : fadeOutHoverImg 1s forwards;
            }
         }
      }
      &-img{
         border-radius       : 6px;
         z-index             : 1;
         position            : absolute;
         background-size     : cover;
         background-position : center;
         height              : 100%;
         width               : 100%;

      }
   }
   &:hover{
      filter : grayscale(100%);
   }
}

.--currentPrj{
   box-shadow:inset 0px 0px 0px 2px $black;
}

@keyframes fadeinHoverText {
   from { opacity : 0; }
   to {   opacity : 1; }
}
@keyframes fadeinHoverImg {
   from {  opacity : 0; }
   to {    opacity : 0.8; }
}

@keyframes fadeOutHoverText {
   from{  opacity : 1; }
   to {   opacity : 0; }
}
@keyframes fadeOutHoverImg {
   from {  opacity : 0.8; }
   to {    opacity : 0; }
}



@media screen and (max-width: 700px) {
   .project-item{
      height : 100px;
   }
}

