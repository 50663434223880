@import '../../Services/Styles.scss';

.hero{
    position         : relative;
    height           : calc(100vh - 20px);
    background-color : $white;
    border-radius    : $border-radius-desktop;
    width            : 100%;
    max-width        : 2000px;
   
    .hide{
        width      : 250px;
        height     : 70px;
        background : $white;
        position   : absolute;
        bottom     : 10px;
        right      : 10px;
        border-bottom-right-radius : $border-radius-desktop;
    }

    &-content{
        display         : flex;
        flex-direction  : column;
        justify-content : space-between;
        position        : absolute;
        height          : calc(100% - $padding-desktop - 20px);
        width           : calc(100% - $padding-desktop - 20px);
        top             : 50%;
        left            : 50%;
        transform       : translate(-50%, -50%);
        color           : $grey02;
        pointer-events  : none;

        &-bottom{
            display         : flex;
            flex-direction  : column;
            justify-content : flex-end;
            align-items     : flex-end;
            flex            : 1;
            text-align      : right;
            line-height     : 130%;
            gap             : 16px;
            text-transform  : uppercase;
        }
    }
}

@media screen and (max-width: 950px) {
    .hero{
        border-radius : $border-radius-mobile;

        &-content{
            height : calc(100% - $padding-mobile - 14px);
            width  : calc(100% - $padding-mobile - 14px);
        }
    }
}