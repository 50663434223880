@import '../../Services/Styles.scss';

.btnMini{
    display          : flex;
    justify-content  : center;
    align-items      : center;
    padding          : 5px 10px;
    border-radius    : 6px;
    width            : fit-content;
    pointer-events   : all;
    gap              : 6px;

    color      : $black;
    border     : 1px solid $black;

    &.--selected{
        background : $black;
        color      : $white;
        border     : 1px solid $black;
    }

    &:hover{
        animation : fadeInBtnMini 0.5s forwards;
    }
    &:not(:hover){
        animation : fadeOutBtnMini 0.5s forwards;
    }
}




@keyframes fadeInBtnMini {
    from {}
    to { 
        background : $black;
        color      : $white;
        border     : 1px solid $black;
        
    }
}
@keyframes fadeOutBtnMini {
    from { 
        color      : $black;
        border     : 1px solid $black;
    }
    to {}
 }
