@import '../../Services/Styles.scss';

.work{
    display          : flex;
    position         : relative;
    background-color : $white;
    color            : $black;
    border-radius    : $border-radius-desktop;
    width            : 100%;
    max-width        : 2000px;

    &-marquee{
        position  : absolute;
        width     : 100%;
    }

    &-content{
        display         : flex;
        flex-direction  : column;
        padding-top     : 120px;
        width           : 100%;
        gap             : $padding-desktop;
        
        &-btns{
            display         : flex;
            flex-direction  : row;
            justify-content : center;
            gap             : 10px;
        }

        &-selected{
            display         : flex;
            flex-direction  : column;
            margin          : $padding-desktop;
            gap             : 10px;
            
            &-type{
                display         : flex;
                flex-direction  : row;
                gap             : 6px;
                font-family     : 'M-SB';
                
                &-date{
                    color : $grey02;
                }
            }
            &-badges{
                display         : flex;
                flex-direction  : row;
                gap             : 10px;
                flex-wrap       : wrap;
            }
            &-text{
                width : 70%;
            }

            &-photos{
                display         : flex;
                flex-direction  : row;
                gap             : 6px;

                &-main{
                    flex                : 1;
                    max-width           : 100%;
                    min-height          : 200px;
                    object-fit          : cover;
                    border-radius       : 10px;
                    background-size     : cover;
                    background-position : center;
                }
                &-othersList{
                    display         : flex;
                    flex-direction  : column;
                    align-items     : center;
                    width           : 250px;
                    height          : 100%;
                    gap             : 6px;

                    &-other{
                        background-size     : cover;
                        background-position : center;
                        width               : 100%;
                        height              : 200px;
                        border-radius       : 10px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 950px) {
    .work{
        border-radius  : $border-radius-mobile;
        
        &-content{
            flex-direction  : column;
            padding-top     : 75px;

            &-selected{
                margin-left     : $padding-mobile;
                margin-right    : $padding-mobile;
                margin-bottom   : $padding-mobile;

                &-text{
                    width : 100%;
                }
                &-photos{
                    display         : flex;
                    flex-direction  : column;
                    gap             : 6px;
                    width           : 100%;
    
                    &-main{
                        min-height    :auto; // mettre ratio
                        border-radius : 6px;
                        padding-bottom: 70%;

                    }
                    &-othersList{
                        display         : flex;
                        flex-direction  : row;
                        width: 100%;
                        gap             : 6px;

                        &-other{
                            width         : 100%;
                            // min-height        : 100px;
                            height : 100px;
                            border-radius : 6px;
                        }
                    }
                }
            }
        }
    }
}

.--currentImg{
    box-shadow:inset 0px 0px 0px 2px $black;
 }