@import '../../Services/Styles.scss';


.badge{
   float            : left;
   display          : block;
   justify-content  : center;
   align-items      : center;
   padding          : 6px 11px;
   font-size        : 12px;
   text-transform   : uppercase;
   color            : $black;
   border-radius    : 3px;
   font-family      : 'M-SB';
}
