@import '../../Services/Styles.scss';

.spline-container{
    display         : flex;
    justify-content : center;
    align-items     : center;
    height          : calc(100%);
    width           : calc(100%);
    border-radius   : $border-radius-desktop;
    position        : absolute;
    top             : 50%;
    left            : 50%;
    transform       : translate(-50%, -50%);

    .spline-content{
        width         : 100%;
        border-radius : $border-radius-desktop;
    }
}
