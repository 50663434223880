@import '../../Services/Styles.scss';

.lang{
   display        : flex;
   background     : $grey01;
   padding        : 8px 15px;
   width          : fit-content;
   gap            : 8px;
   border-radius  : 99px;
   color          : $grey02;
   font-size      : 15px;
   pointer-events : all;

   &-selected{
      animation : fadeIn 0.5s forwards;
   }
}


@keyframes fadeIn {
   from {
      color       : $grey02;
      font-family : 'M-R';
   }
   to { 
      color       : $black;
      font-family : 'M-SB';
   }
}
