@import '../../Services/Styles.scss';

.btnRounded{
    display          : flex;
    justify-content  : center;
    align-items      : center;
    color            : $white;
    background-color : $black;
    padding          : 8px 14px;
    border-radius    : 99px;
    width            : fit-content;
    pointer-events   : all;
    border           : 1px solid $white;
    gap              : 6px;

    &:hover{
        animation : fadeInBtn 0.5s forwards;
        svg {
            path {
                stroke: $black;
            }  
        }
    }
    &:not(:hover){
        animation : fadeOutBtn 0.5s forwards;
    }
}

@keyframes fadeInBtn {
    from {
        background : $black;
        color      : $white;
        border     : 1px solid $white;
    }
    to { 
        background : $white;
        color      : $black;
        border     : 1px solid $black;
        
    }
}
@keyframes fadeOutBtn {
    from { 
        background : $white;
        color      : $black;
        borde      : 1px solid $black;
    }
    to { 
        background : $black;
        color      : $white;
        border     : 1px solid $white;
    }
 }
